<template>
  <section class="my-5">
    <div class="mb-5 text-center">
      <h1>Edit GiftCard Entry</h1>
    </div>
    <EditCardEntryForm />
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import EditCardEntryForm from "@/views/cards/constituent/EditCardEntryForm.vue";

export default defineComponent({
  name: "EditGiftCardEntry",
  components: {
    EditCardEntryForm,
  },
  setup() {
    return {};
  },
});
</script>

<style scoped></style>
