
import { defineComponent } from "vue";
import EditCardEntryForm from "@/views/cards/constituent/EditCardEntryForm.vue";

export default defineComponent({
  name: "EditGiftCardEntry",
  components: {
    EditCardEntryForm,
  },
  setup() {
    return {};
  },
});
