<template>
  <div class="row justify-content-center my-5">
    <div class="card col-12 col-md-6 col-lg-4">
      <DualRingLoader :loading="loading">
        <div class="card-body">
          <form v-if="loaded" class @submit.prevent="Submit">
            <div class="mb-3">
              <div>
                <label for="description" class="form-label">Type/Description</label>
                <InputWrapper :errors="formError.description">
                  <input
                    type="text"
                    v-model="formData.description"
                    class="form-control"
                    id="description"
                    required
                  />
                </InputWrapper>
              </div>
            </div>

            <div class="mb-3">
              <div>
                <label for="description" class="form-label">Range/Denomination</label>
                <InputWrapper :errors="formError.range">
                  <input
                    type="text"
                    pattern="^(?:\d+)(?:(?:-\d+)|(?:,\d+)+)?$"
                    v-model="formData.range"
                    class="form-control"
                    id="range"
                    required
                  />
                </InputWrapper>
              </div>
            </div>

            <div class="mb-3">
              <div>
                <label for="rate" class="form-label">Rate</label>
                <InputWrapper :errors="formError.rate">
                  <input
                    type="number"
                    v-model="formData.rate"
                    class="form-control"
                    id="rate"
                    step="0.01"
                    required
                  />
                </InputWrapper>
              </div>
            </div>

            <!-- check specific rate -->
            <div class>
              <!-- set amount and preview -->
              <div class="mb-3">
                <div>
                  <label for="rate" class="form-label">Amount</label>
                  <InputWrapper :errors="formError.rate">
                    <input
                      type="number"
                      v-model="formData.preview"
                      class="form-control"
                      id="rate"
                      step="0.01"
                      placeholder="Enter amount "
                      required
                    />
                    <!-- @input="formData.preview = formData.amount * formData.rate" -->
                  </InputWrapper>
                </div>
              </div>

              <div class="d-flex flex-column mb-1">
                <!-- set preview -->
                <div v-for="currency in fiatCurrencies" :key="currency.id" class="mb-2">
                  <div class="text-end">
                    <h5>
                      {{ currency.code }}
                      <span class="badge bg-dark">{{ currency.we_buy }}</span>
                    </h5>

                    <!-- <span class="me-2 d-block">{{ currency.code }} ({{ currency.we_buy }}):</span> -->
                    <span
                      class="h6"
                    >{{ (formData.preview * formData.rate * currency.we_buy).toFixed(4) }}</span>

                    <!-- <span>{{ currency.we_buy }}We buy</span>
                    <span>{{ currency.we_sell }}We Sell</span>-->
                  </div>
                </div>
              </div>
            </div>

            <div class="mb-3">
              <div>
                <label for="tac" class="form-label">Terms And Condition</label>
                <InputWrapper :errors="formError.rate">
                  <textarea class="form-control" id="tac" rows="6" v-model="formData.tac" required></textarea>
                </InputWrapper>
              </div>
            </div>

            <div class="text-center">
              <button class="btn btn-dark">Save Card Entry</button>
            </div>
          </form>
          <div v-else>
            <h1 class="text-center">Unable to fetch entry</h1>
            <div class="text-center">
              <button
                type="button"
                class="btn btn-sm btn-outline-primary"
                @click="RefreshData"
              >Retry</button>
            </div>
          </div>
        </div>
      </DualRingLoader>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, ref } from "vue";
import { apiGet, apiPut } from "@/api";
import DualRingLoader from "@/components/loader/DualRingLoader.vue";
import { Toast } from "@/utils/notify";
import { useRoute } from "vue-router";
import { ShowSuccess } from "@/utils/notify";
import InputWrapper from "@/components/InputWrapper.vue";

export default defineComponent({
  name: "EditCardEntryForm",
  components: { InputWrapper, DualRingLoader },
  emits: ["save"],
  setup() {
    const dollarRate = {
      id: -1,
      name: "Dollar",
      code: "USD",
      we_sell: 1,
      we_buy: 1,
    };
    const fiatCurrencies = ref([dollarRate]);

    const formData = ref({
      description: null,
      rate: null,
      range: null,
      tac: null,
      preview: null,
      // amount: null,
    });
    const loaded = ref(false);
    const formError = ref({});
    const route = useRoute();
    const loading = ref(false);
    async function Submit() {
      loading.value = true;
      try {
        await apiPut(
          `/admin/cards/entry/${route.params.entryId}`,
          formData.value
        );
        ShowSuccess("Entry Updated Successfully");
      } catch (e) {
        formError.value = e?.response?.data?.i ?? {};
        Toast.fire({
          icon: "warning",
          title: "Unable to create entry: " + e.message,
        }).then();
      }
      loading.value = false;
    }

    const RefreshData = async () => {
      loading.value = true;
      try {
        const response = await apiGet(
          `/admin/cards/entry/${route.params.entryId}`
        );

        const fiatDataResponse = await apiGet(
          `/misc/fiat-currencies`,
          (fiatCurrencies.value as any)?.meta ?? {}
        );
        fiatCurrencies.value = [dollarRate, ...fiatDataResponse.data.data.fiat];

        console.log(fiatCurrencies.value);

        const entry = response.data.data.entry;
        formData.value.description = entry.description;
        formData.value.rate = entry.rate;
        formData.value.range = entry.range;
        formData.value.tac = entry.tac;
        // formData.value.preview = entry.rate + 10;
        loaded.value = true;
        Toast.fire({
          icon: "success",
          title: "Card Entry fetched",
        }).then();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to fetch data: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    onBeforeMount(RefreshData);
    return {
      Submit,
      RefreshData,
      fiatCurrencies,
      loaded,
      formData,
      formError,
      loading,
    };
  },
});
</script>

<style scoped></style>
